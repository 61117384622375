import React, { useEffect } from "react";
import parse from 'html-react-parser';

var projectList = [
  {
    imageLocation: "/css/img/couses/2024_Swachhata_Hi_Seva.jpg",
    date: "SEP 2024",
    projectTitle:
      "Swachhata Hi Seva",
    time: "",
    location: "North Gujarat",
    description:
      "To create a clean and healthy environment for all citizens, foster a sense of community ownership and participation in cleanliness efforts and promote sustainable practices and reduce environmental pollution Swachhata Hi Seva initiative was undertaken. Activities blood testing for 100 safai mitra, cleanliness drive, medical camp and 100 safai mitra samman, dustbin installation, debate competition in school, jute bag distribution at vegetable market were undertaken.",
  },
  {
    imageLocation: "/css/img/couses/2024_Swachhta_Pakhwada.jpg",
    date: "JUL 2024",
    projectTitle:
      "Celebration of Swachhta Pakhwada",
    time: "",
    location: "Mehsana",
    description:
      "Swachhta pakhwada was celebrated during 1st July 2024 to 15th July 2024. Activities like kick-off of swachhta pakhwada with swachhta pledge & cleanliness drive with, jute bag distribution, swachhta awareness, iec program, dustbin distribution, cleanliness drive and tree plantation in school, walkathon along with awareness and cleanliness drive, cleaning & awareness drive at heritage place, sanitary pads and incinerator distribution, personal hygiene kit distribution, essay, drawing, debate competition on cleanliness,  essay on cleanliness,  cleaning & awareness drive at forest area and  safai yoddha sammaan & valedictory function were undertaken during those days.",
  },
  {
    imageLocation: "/css/img/couses/2024_Blood_Donation.jpg",
    date: "JAN 2024",
    projectTitle:
      "Blood donation and awareness generation camp",
    time: "",
    location: "Mehsana",
    description:
      "Organizing the blood donation camp on March 21, 2024, at the Officer's Club in N.K. Bhavan, ONGC Nagar, Palavasna, Mehsana, are truly noteworthy. MRCF’s dedication to saving lives through blood donation is evident in their successful collaboration with the Mehsana Jesis Charitable Trust and the Sarvoday Voluntary Blood Bank. The participation of ONGC staff in this noble cause is a testament to their compassion and social responsibility.",
  },
  {
    imageLocation: "/css/img/couses/2023_Cataract.jpg",
    date: "SEP 2023",
    projectTitle:
      "Cataract Surgery for Rural Community and Awareness Generation",
    time: "",
    location: "Patan, Gandhinagar and Mehsana",
    description:
      "In partnership with Jyoti Eye Hospital, we have conducted cataract surgery for 300 cataract surgery were performed in Patan, Gandhinagar and Mehsana district. This initiative aims to improve the vision and quality of life of individuals who lack access to essential eye care services, addressing a prevalent cause of visual impairment in these communities.  This intervention for “Cataract Surgery” made a huge positive impact on the villagers as many of these villages do not even have access to basic medical facilities.",
  },
  {
   imageLocation: "/css/img/couses/ANCKIT_Vijapur.jpg",
    date: "JUL 2023",
    projectTitle:
      "Nutrition kit distribution: 21 ANC Women",
    time: "",
    location: "Vasai (Vijapur Taluka)",
    description:
      "A nutrition kit distribution program targeting 21 antenatal care (ANC) women was undertaken in Vasai (Vijapur Taluka), an effort to boost maternal health and improve nutrition among pregnant women. The effort aims to address the special nutritional demands of expecting moms and supply important nutrients for their well-being. Each kit included a variety of nutritional items to ensure a well-balanced diet for optimal prenatal growth. By focusing on ANC women's nutritional needs, this program aimed to improve their health outcomes and contribute to healthier pregnancies and communities generally.",
  },
  {
    imageLocation: "/css/img/couses/03032023_Gram_Bharti.jpg",
    date: "JUN 2023",
    projectTitle:
      "Shri Motibhai Chaudhary Birth Centenary Service Honorary Award",
    time: "10:00AM - 12:30PM",
    location: "Amrapur",
    description:
      "18<sup>th</sup> Remembarance lecture and Birth Centenary Service Honorary Award Presentation Programme on the occasion of Shri Motibhai Chaudhary's 100<sup>th</sup>  birth anniversary was organised on 3<sup>rd</sup> July 2023. Dr. Raghuveer Chaudhary inagurated the programme. Shri P. K. Laheri (Ex. Chief Secretary, Govt. of Gujarat) given his lacture on this occasion.This programme was organised at Motibhai Chaudhary Hall in Gram Bharti, Amrapur.",
  },{
    imageLocation: "/css/img/couses/FG2G.jpg",
    date: "JUN 2023",
    projectTitle:
      "Agriculture and Animal Husbandary Camp",
    time: "9:00AM - 12:00PM",
    location: "Mansa",
    description:
      "Agriculture and Animal Husbandry Camp was held at Mansa for Centenary Salute (1923 to 2023)- Memories of 35 years of Skilled, Competent, Successful and Unprecedented leadership of Shri Motibhai R. Chaudhary. More than 700 farmers was invited in the camp. \
      Agriculture and Animal Husbandry Camp was organised by Shri Akhil Anjana Kelavani Mandal (Mansa) and Shri Motibhai R. Chaudhary Foundation (Mehsana).\
      Under the leadership of Shri Hashmukhbhai Patel, camp was organised. Shri Haribhai Chaudhary inaugurated the camp along with the chief guests Shri Karshanbhai Chaudhary, Shri Kanubhai Chaudhary and  Shri Yogesh Patel.",
  },
  {
    imageLocation: "/css/img/couses/2020606 ANC.jpg",
    date: "JUN 2023",
    projectTitle:
      "Nutrition kit distribution: 21 ANC Women",
    time: "",
    location: "Bechraji",
    description:
      "A nutrition kit distribution program targeting 21 antenatal care (ANC) women was undertaken in Bechraji, in an effort to boost maternal health and improve nutrition among pregnant women. The effort aims to address the special nutritional demands of expecting moms and supply important nutrients for their well-being. Each kit included a variety of nutritional items to ensure a well-balanced diet for optimal prenatal growth. By focusing on ANC women's nutritional needs, this program aimed to improve their health outcomes and contribute to healthier pregnancies and communities generally.",
  },
  {
    imageLocation: "/css/img/couses/2020606 ANC_Satlasana.jpg",
    date: "JUN 2023",
    projectTitle:
      "Nutrition kit distribution: 21 ANC Women",
    time: "",
    location: "Satlasana",
    description:
      "A nutrition kit distribution program targeting 21 antenatal care (ANC) women was undertaken in Satlasana, an effort to boost maternal health and improve nutrition among pregnant women. The effort aims to address the special nutritional demands of expecting moms and supply important nutrients for their well-being. Each kit included a variety of nutritional items to ensure a well-balanced diet for optimal prenatal growth. By focusing on ANC women's nutritional needs, this program aimed to improve their health outcomes and contribute to healthier pregnancies and communities generally.",
  },
  {
    imageLocation: "/css/img/couses/2022_Underground_WaterTank.jpg",
    date: "JAN 2022",
    projectTitle:
      "Construction of 1.5 lakh liter underground tank constructed in Ambaji - Adivasi Ashram Shala",
    time: "",
    location: "Ambaji",
    description:
      "Labour Welfare Trust Siddhpur received assistance and financial support for the construction of a 1.5 lakh litre underground tank at Adivasi Ashram Shala in Ambaji from Motibhai R. Chaudhary Foundation.",
  },
  {
    imageLocation: "/css/img/couses/2021_Jal_Jeevan.jpg",
    date: "OCT 2021",
    projectTitle: `MoU for undertaking IEC and capacity building activities/performance in village water supply scheme under "Jal Jeevan Mission" programme.`,
    time: "",
    location: "",
    description: "Training for working out cost of operation and tariff, Training of FTK with demonstration, Training of skill manpower such as pump operator, plumber and electrician, Water Account Management, Explanation of various provisions of punishment and penalties under Gujarat Domestic Water (Protection) Act, Village Action plan for Gray Water Management, Important porvision of water and Sanitation under 15 Finance Commission, Role of Rechlorination in Village distribution system, Benefits of daily water supply instead of alternative of periodic and required training for such management, Street Play covering water account, Chlorination, water quality, daily service level of water and provisions of the Gujarat Domestic Water Supply Act, MIS data management",
  },
  {
    imageLocation: "/css/img/couses/2019_Harij_Rajpura.jpg",
    date: "JAN 2019",
    projectTitle:
      "Harij (Rajpura) Functioning of Participatory Irrigation Management (PIM)",
    time: "",
    location: "Harij (Rajpura)",
    description:
      "The first phase of PIM was carried out in 4981.37 hectares under the Sardar Sarovar (Narmada) scheme. Awareness on participatory irrigation management, conducting meetings of the councillors of irrigation cooperative society, training, organising exposure visits, formation of cooperative societies based on irrigated area, collection of public contribution, apply for registration of after acceptance of formation of co-operative society, formal registration of society, training on the operation of co-operative societies, awareness generation on technical, institutional, financial matters and construction of drains etc., handing over the administration part to the cooperative society, providing guidance on technical, management and financial matters, use water sparingly, charging water proportions, provide insightful guidance on depositing irrigation amount in SSNNL.",
  },
  {
    imageLocation: "/css/img/couses/2018_Adipur_PIM.jpg",
    date: "JAN 2018",
    projectTitle:
      "Adipur (Kachchh) Functioning of Participatory Irrigation Management (PIM)",
    time: "",
    location: "Adipur (Kachchh)",
    description:
      "The second phase of PIM was carried out in 3709 hectares under the Sardar Sarovar (Narmada) scheme. Awareness on participatory irrigation management, conducting meetings of the councillors of irrigation cooperative society, training, organising exposure visits, formation of cooperative societies based on irrigated area, collection of public contribution, apply for registration of after acceptance of formation of co-operative society, formal registration of society, training on the operation of co-operative societies, awareness generation on technical, institutional, financial matters and construction of drains etc., handing over the administration part to the cooperative society, providing guidance on technical, management and financial matters, use water sparingly, charging water proportions, provide insightful guidance on depositing irrigation amount in SSNNL.",
  },
  {
    imageLocation: "/css/img/couses/2014_Bio_Gas.jpg",
    date: "DEC 2014",
    projectTitle: "Financial Assistance for Bio Gas Plant in Tribal Village",
    time: "",
    location: "Sembalpani, Guda, Padlia or Viramveri villages of Danta Taluk",
    description: "Constructed 51 Bio Gas Plant in tribal village",
  },
  {
    imageLocation: "/css/img/couses/2014_PIM_Sultanpura.jpg",
    date: "OCT 2014",
    projectTitle:
      "Participatory Irrigation Management (PIM) Work - Narmada Irrigation Area",
    time: "",
    location: " Sultanpura, Bandhwad, Surka, Chalawada, Subapura and Dev",
    description: "PIM work has been carried out in 2688 hectares under the Sardar Sarovar (Narmada) scheme. \
    Awareness on participatory irrigation management, conducting meetings of the councillors of irrigation cooperative society, training, organising exposure visits, formation of cooperative societies based on irrigated area, collection of public contribution, apply for registration of after acceptance of formation of co-operative society, formal registration of society, training on the operation of co-operative societies, awareness generation on technical, institutional, financial matters and construction of drains etc., handing over the administration part to the cooperative society, providing guidance on technical, management and financial matters, use water sparingly, charging water proportions, provide insightful guidance on depositing irrigation amount in SSNNL.",
  },
  {
    imageLocation: "/css/img/couses/Adipur42.jpg",
    date: "FEB 2014",
    projectTitle:
      "Participatory Irrigation Management (PIM) Work - Narmada Irrigation Area",
    time: "",
    location: "Delana, Gulabpura, Gotarka, Dehgam, Dholkada, Abiyana, Lunichana, Adgam, Undi, Gokhantar, Chaniyathar, Pedashpura, Gadsai and Karshangarh",
    description:
      "PIM work has been carried out in 4161 hectares under the Sardar Sarovar (Narmada) scheme. \
      Awareness on participatory irrigation management, conducting meetings of the councillors of irrigation cooperative society, training, organising exposure visits, formation of cooperative societies based on irrigated area, collection of public contribution, apply for registration of after acceptance of formation of co-operative society, formal registration of society, training on the operation of co-operative societies, awareness generation on technical, institutional, financial matters and construction of drains etc., handing over the administration part to the cooperative society, providing guidance on technical, management and financial matters, use water sparingly, charging water proportions, provide insightful guidance on depositing irrigation amount in SSNNL.",
  },
  {
    imageLocation: "/css/img/couses/Yoganjali.jpg",
    date: "FEB 2014",
    projectTitle:
      "Aquifer Mapping Survey - Pilot Project under Open Well, Borewell and Ground Water Well",
    time: "",
    location: "Kalol, Gandhinagar, Mandal",
    description: "Field Data Collection - Lat, Long, MSL, Water Quality Analysis (EC, pH, Temperature, TDS)",
  },
  {
    imageLocation: "/css/img/couses/2013_SSP_Command.jpg",
    date: "DEC 2013",
    projectTitle:
      "Participatory Irrigation Management (PIM) in SSP command area (Naga Vadiliya Distributary & Vira Vadiliya Distributary)",
    time: "",
    location: "Vira, Bita Ranmalvas, Naga Validiya, Hatdi, Bita Dudavas, Kukadsar",
    description: "PIM work has been carried out in 3709 hectares in SSP command area. \
    Awareness on participatory irrigation management, conducting meetings of the councillors of irrigation cooperative society, training, organising exposure visits, formation of cooperative societies based on irrigated area, collection of public contribution, apply for registration of after acceptance of formation of co-operative society, formal registration of society, training on the operation of co-operative societies, awareness generation on technical, institutional, financial matters and construction of drains etc., handing over the administration part to the cooperative society, providing guidance on technical, management and financial matters, use water sparingly, charging water proportions, provide insightful guidance on depositing irrigation amount in SSNNL.",
  },
  {
    imageLocation: "/css/img/couses/Sultanpura52.jpg",
    date: "JUL 2013",
    projectTitle:
      "Participatory Irrigation Management (PIM) Work - Narmada Irrigation Area",
    time: "",
    location: "Khorsam, Gokharwa, Kamboi, Itoda, Tambodiya, Sodhav, Govana, Dantarwada Harij, Delmal, Naranpura, Paloli, Aritha, Buda, Rasulpura and Mandali of Harij and Delmal sub-branches.",
    description: "PIM work has been carried out in 5425.88 hectares in SSP command area. \
    Awareness on participatory irrigation management, conducting meetings of the councillors of irrigation cooperative society, training, organising exposure visits, formation of cooperative societies based on irrigated area, collection of public contribution, apply for registration of after acceptance of formation of co-operative society, formal registration of society, training on the operation of co-operative societies, awareness generation on technical, institutional, financial matters and construction of drains etc., handing over the administration part to the cooperative society, providing guidance on technical, management and financial matters, use water sparingly, charging water proportions, provide insightful guidance on depositing irrigation amount in SSNNL.",
  },
  {
    imageLocation: "/css/img/couses/Sultanpura2009.jpg",
    date: "APR 2013",
    projectTitle:
      "Rapid development and speed up the implementation of the scheme in Cultivable Command Area/Cultivable Irrigated Area of Radhanpur Branch Canal (SSNNL)",
    time: "",
    location: "Uan Distributary 5 Village (Padar, Nekariya, Jotada Tebi, Uan)\
    Bhadiya Distributary 10 Village(Jotada, Mandala, Tebi, Tantiyana, Uan, Ratanpura, Vadnagar, Jetalpur, Bhaidya)\
    Gosan Distributary 10 Village (Subapura, Sultanpura, Dev, Varsada, Royta, Chichodara, Chalvada, Gosan, Mandala, Subapura)",
    description: "5000 hector was covered. Activities caried out: \
    Society Registration, Member registration in society, Formalisation of Committee, Public Awareness, Training Workshop, Awareness on Co-operative Act, Accounts, Audit and Recovery work, Conjunctive use of Water, Awareness about construction Work, Gran Sabha, Awareness Tour.",
  },
  {
    imageLocation: "/css/img/couses/EAP.jpg",
    date: "FEB 2013",
    projectTitle:
      "Enviornment Awarness Programme - Conservation of Biodiversity",
    time: "",
    location: "Khatchitra (Amirgadh)",
    description: "Enviornment Awarness Programme, Tree Plantationa, Awarness on Conservation of Biodiversity, Training"
  },
  {
    imageLocation: "/css/img/couses/Surpura2009.jpg",
    date: "OCT 2011",
    projectTitle:
      "Participatory Irrigation Management (PIM) Work - Narmada Irrigation Area",
    time: "",
    location: "Ambala, Mandalay, Chandroda, Surpura",
    description: "PIM work has been carried out in 569.59 hectares in SSP command area. \
    Awareness on participatory irrigation management, conducting meetings of the councillors of irrigation cooperative society, training, organising exposure visits, formation of cooperative societies based on irrigated area, collection of public contribution, apply for registration of after acceptance of formation of co-operative society, formal registration of society, training on the operation of co-operative societies, awareness generation on technical, institutional, financial matters and construction of drains etc., handing over the administration part to the cooperative society, providing guidance on technical, management and financial matters, use water sparingly, charging water proportions, provide insightful guidance on depositing irrigation amount in SSNNL.",
  },
  {
    imageLocation: "/css/img/couses/July2009.jpg",
    date: "JUL 2009",
    projectTitle:
      "Facilitating IEC and construction activities pertaining of roof top rainwater harvesting tanks for individual household for providing water security in salinity area of Patan district",
    time: "",
    location: "Selected areas of Patan district",
    description: "IEC and Construction Activities for roof top rainwater harvesting tanks for individual household - 50 Water Tanks for 50 beneficiaries",
  },
  {
    imageLocation: "/css/img/couses/2009_Sidhada.jpg",
    date: "JUN 2009",
    projectTitle:
      "Facilitating IEC and construction activities pertaining of roof top rainwater harvesting tanks for individual household for providing water security in Sidhada village of Santalpur taluka of Patan district",
    time: "",
    location: "Sidhada",
    description: "IEC and Construction Activities for roof top rainwater harvesting tanks for individual household - 50 Water Tanks for 50 beneficiaries",
  },
  {
    imageLocation: "/css/img/couses/2009_Khapra_Checkdam.jpg",
    date: "FEB 2009",
    projectTitle:
      "Check Dam and Nalla Plugging under Ground Water Recharging Work at Khapra-1 & Khapra-2 Village in Amirgadh Taluka",
    time: "",
    location: "Khapra-1 & Khapra-2 Village (Amirgadh)",
    description: "Ground Water Recharging Structure Work funded by Gujarat Water Supply and Sewerage Board in Khapra-1 & Khapra-2 Village of Amirgadh Taluka.",
  },
  {
    imageLocation: "/css/img/couses/2009_Danta.jpg",
    date: "JAN 2009",
    projectTitle:
      "Rainwater Harvesting work in Aadarsh Residential School at Danta",
    time: "",
    location: "Danta (Ambaji)",
    description: "Construction of rain water harvesting tank in Aadarsh Residential School (Danta, Ambaji). Awarness generation, Trainning and Capacity Building",
  }
];

const Project = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "MRCF | Projects";
  });
  return (
    <>
      <section class="page-title-area">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 position-relative">
              <div class="page-title text-center">
                <h1>PROJECTS</h1>
              </div>
              <div class="breadcrumbs-area  text-center">
                <ol class="breadcrumbs">
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li class="active">PROJECTS</li>
                </ol>
              </div>
              <div class="back-home">
                <a href="/">
                  <i class="fa fa-arrow-left" aria-hidden="true"></i> BACK TO
                  HOME
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="up-comming-area  pt-90 pb-60">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div class="up-comming-events">
                {projectList.map((data, index) => (
                  <ProjectCard key={index} data={data} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const ProjectCard = ({ data }) => {
  return (
    <>
      <div class="single-event mb-90 row">
        <div class="col-md-5">
          <div class="event-image">
            <img src={data.imageLocation} alt="" />
            <h3>{data.date}</h3>
          </div>
        </div>
        <div class="col-md-7">
          <div class="event-text">
            <h3>
              <a href="#">{data.projectTitle}</a>
            </h3>
            <div class="event-time-and-place">
              {data.time != "" ? (
                <>
                  <span class="ev-time">
                    {" "}
                    <i class="fa fa-clock-o" aria-hidden="true"></i>
                    {data.time}
                  </span>
                </>
              ) : (
                <></>
              )}
              {data.location != "" ? (
                <>
                  <span class="ev-place">
                    {" "}
                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                    {data.location}
                  </span>
                </>
              ) : (
                <></>
              )}
            </div>
            <p>{parse(data.description)}</p>
            {/* <a class="btn-md bo-ra-5" href="#">
              Read More
            </a> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Project;
